<template>
  <div class="course-detail">
    <van-nav-bar class="course-detail-title" fixed left-arrow @click-left="onBack" style="background: none;">
      <template #right @click-right="userKefu">
        <van-icon name="share-o" />
      </template>
    </van-nav-bar>

    <div class="banner-course-detail">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#1989fa" @change="onChange">
        <van-swipe-item>
          <img :src="info.imgurl" width="100%" style="display: block;" alt />
        </van-swipe-item>
<!--        <div class="custom-indicator" slot="indicator">-->
<!--          {{ current + 1 }}/{{info.photopath.length}}-->
<!--        </div>-->
      </van-swipe>
    </div>
    <div class="course-title">
      <!-- <van-tag type="warning">直播+录播</van-tag> -->
      {{info.title}}
      <div class="course-price" v-if="branchId == 3 ||branchId == 5">￥咨询服务老师</div>
      <div class="course-price" v-else>￥{{info.group_money ? info.group_money : '咨询服务老师'}}</div>
    </div>
<!--    <van-cell center title="课程服务" class="course-service" @click="serviceShow = true">-->
<!--      <template #label>-->
<!--&lt;!&ndash;        <van-tag plain color="#999999" v-for="(item,index) in info.service">{{item.title}}</van-tag>&ndash;&gt;-->
<!--      </template>-->
<!--      <template #right-icon>-->
<!--        <van-icon name="weapp-nav" class="weapp-nav-icon" />-->
<!--      </template>-->
<!--    </van-cell>-->
    <van-tabs v-model:active="active1" color="#1989fa" swipeable sticky animated class="shop_detail">
      <van-tab title="详情">
        <div v-html="info.content" class="detail"></div>
      </van-tab>
    </van-tabs>
    <van-action-sheet v-model:show="teacherShow" title="我的服务老师" class="teacher-popup">
      <div class="content2" height="40%">
        <input type="hidden" id="input">
        <van-cell center :title="service.real_name" v-if="service.phone">
          <template #icon>
            <van-icon name="phone-o" />
          </template>
          <template #label>
            <span @click="copy(service.phone)">{{service.phone}}<van-tag style="margin-left:0.6rem">复制</van-tag></span>
          </template>
        </van-cell>
        <van-cell center :title="service.real_name" v-if="service.we_chat_code">
          <template #icon>
            <van-icon name="chat-o" />
          </template>
          <template #label>
            <span @click="copy(service.we_chat_code)">{{service.we_chat_code}}<van-tag style="margin-left:0.6rem">复制</van-tag></span>
          </template>
        </van-cell>
        <van-image class="pho-wx" width="20rem" height="20rem" fit="contain"
          :src="service.code" />
      </div>
    </van-action-sheet>

    <van-goods-action>
      <van-goods-action-icon icon="chat-o" text="客服" @click="teacherShow = true" />
      <!-- <van-goods-action-icon icon="cart-o" text="购物车" info="9" @click="handelCart" /> -->
<!--      <van-goods-action-icon icon="like-o" text="收藏" @click="handelCollectAdd" />-->
<!--       <van-goods-action-button type="warning" text="加入购物车" @click="handelCartAdd" />-->
<!--      <van-goods-action-button type="danger" text="咨询服务老师购买课程" @click="handelBuy" />-->
      <van-goods-action-button type="danger" text="立即购买" v-if="show_admin===false" @click="submitOrder" />
      <van-goods-action-button type="danger" text="咨询服务老师购买图书" v-else @click="teacherShow = true" />
    </van-goods-action>


  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import axios from "axios"


import { getBookDetail,informationDetail,informationDetail2 } from '@/request/api'
import {Toast} from "vant";
import fa from "element-ui/src/locale/lang/fa";

export default {
  beforeRouteEnter(to, from, next) {
    let that = this;
    // console.log(to);
    // console.log(from);
    // console.log(next);
    if (!to.params || !to.query.id) {
        next({
            path: from.name,
            query: {} // 将跳转的路由path作为参数，登录成功后跳转到该路由
        })
    }
    next();
  },
  // metaInfo: {
  //   title: '2022中药全科（精品班）', //设置页面title
  //   meta: [{                 //设置页面meta
  //     name: 'keyWords',
  //     content: '关键词'
  //   },
  //   {
  //     name: "description",
  //     content: "描述",
  //   },
  //   ]
  // },
  metaInfo() {
    return {
      title: this.title, //设置页面title
    }
  },
  data() {
    return {
      title: this.getBranchCompany(),
      //轮播图数组
      banner: [],
      current: 0,
      active: '',
      serviceShow: false,
      teacherShow: false,
      status: '',
      active1:'',
      shopList: [
        {
          goods_title: "2022年中药全科（精品班）",
          goods_desc: "导学班+精讲班+强化班+真题班+习题班+点睛班",
          goods_thumb: "https://cdnoss.ksyt.com.cn/weixin/banci.png",
          goods_price: 980.00
        },
        {
          goods_title: "2022年中药全科（精品班）",
          goods_desc: "导学班+精讲班+强化班+真题班+习题班+点睛班",
          goods_thumb: "https://cdnoss.ksyt.com.cn/weixin/banci.png",
          goods_price: 980.00
        },
        {
          goods_title: "2022年中药全科（精品班）",
          goods_desc: "导学班+精讲班+强化班+真题班+习题班+点睛班",
          goods_thumb: "https://cdnoss.ksyt.com.cn/weixin/banci.png",
          goods_price: 980.00
        }
      ],
      bookId: '',
      info: [],
      courseList: [],
      service: [],
      branchId: this.getBranch(),
      show_admin:true,
    };
  },
  methods: {
    handleClickSearch() {
      this.$router.push('/course/searchPopup')
      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("changeIsShowPopupShow", true)
    },
    onChange(index) {
      this.current = index;
    },
    handelService() {
      this.$router.push('/course/searchPopup')
      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("changeIsShowPopupShow", true)
    },
    handelCart() {
      this.$router.push('/cart')
      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("changeIsShowPopupShow", true)
    },
    handelCollectAdd() {
      let status = true
      const p = document.getElementsByClassName('van-goods-action-icon')
      // const handelCollectAdd = () => {
      if (status) {
        status = false
        // console.log(p[2].childNodes[0])
        p[2].childNodes[0].classList.replace('van-icon-like-o', 'van-icon-like')
        p[2].childNodes[1].data = '已收藏'
        console.log(p[2])
      } else {
        status = true
        p[2].childNodes[0].classList.replace('van-icon-like', 'van-icon-like-o')
        p[2].childNodes[1].data = '收藏'
        console.log("进入收藏")
      }
      // }
    },
    handelCartAdd() {
      Toast('功能开发中');
      // this.$router.push('/courseDetail/searchPopup')
      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("changeIsShowPopupShow", true)
    },
    handelBuy() {
      // this.$router.push('/courseDetail/searchPopup')
      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("changeIsShowPopupShow", true)
      Toast('去咨询服务老师');
    },
    handelCommodityEvaluation() {
      this.$router.push('/commodityEvaluation')
      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("changeIsShowPopupShow", true)
    },

    onBack() {
      // history.back();
      window.android.back()
    },
    userKefu() {
      this.$router.push({ path: '/user' });
    },

    bookDetail() {
      let bookId = this.bookId;
      let that = this;
      getBookDetail({id: bookId})
          .then(res => {
            if (res.data.code == 1) {
              that.info = res.data.data.info;
            }
          })
          .catch(err => {
            console.log("error");
            console.log(err);
          })
      if(that.getToken()) {
        informationDetail()
            .then(res => {
              if (res.data.code == 1) {
                that.service = res.data.data.data;
              }
            })
            .catch(err => {
              console.log("error");
              console.log(err);
            })
      } else {
        informationDetail2({branch:that.getBranch()})
            .then(res => {
              if (res.data.code == 1) {
                that.service = res.data.data.data;
              }
            })
            .catch(err => {
              console.log("error");
              console.log(err);
            })
      }
    },
    handleClickCourseDetail(id) {
      this.$router.push({
        name: 'CourseDetail',
        params: {
          id
        }
      })
    },
    copy(title) {
      this.$copyText(title).then(
              function(e) {
                Toast('复制成功')
              },
              function(e) {
                Toast('复制错误')
              }
      );
//       console.log(title)
// //获取input对象
//       var input = document.getElementById("input");
//       console.log(input);
//       input.value = 123123; // 要复制的文本框的内容（此处是后端返回的内容）
//       input.select(); // 选中文本
//       document.execCommand("copy");
//       Toast('复制成功');
    },
    submitOrder(){
      if(this.show_admin === true){
        this.teacherShow = true;
        return false;
      }
      let id =  this.info.id;
      this.$router.push({name: 'Order', query:{id},params: {id},})
    }
  },
  created() {
    this.bookId = this.$route.query.id;
    this.bookDetail();
    this.title = this.getBranchCompany()
    // request.get("/index/index")
  },
  components: {

  },

}
</script>
<style lang="less">
@import '~@/assets/styles/common.less';

.course-detail {
  background-color: #ffffff;
  // overflow: hidden;
}

.popup-shadow {
  width: 100%;
  height: 100%;
  position: absolute;
  // z-index: 99999;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, .5);
  z-index: 20;
}

// .slide-enter, .slide-leave-to {
//   // 过渡之前的样式写在这里
//    right: -100%;
// }
// .slide-enter-active,.slide-leave-active  {
//   // 过渡属性写在这里
//   transition: all .3s;
// }
// .slide-enter-to, .slide-leave {
//   // 过渡之后的样式写在这里
//    right: 0;
// }
.shoplist1 {
  padding: 0.8rem;
  background-color: #ffffff;
}

.course-title {
  padding: 2.4rem 1.5rem 1.5rem 1.5rem;
  font-size: 2.4rem;
  line-height: 3.6rem;
  font-weight: 600;
  color: #333;
}

.course-title .van-tag {
  padding: 0.3rem 0.6rem;
  position: relative;
  bottom: 0.2rem;
  margin-right: 0.6rem;
}

.course-title .course-price {
  padding-top: 0.5rem;
  color: #ee0a24;
  font-weight: 600;
  font-size: 2.4rem;
}

.course-service {
  padding: 0 1.5rem;
}

.course-service .van-tag {
  margin-right: 0.4rem;
}

.course-service .weapp-nav-icon {
  font-size: 3rem;
}

.service-popup {
  height: 50% !important;
  ;
}

.service-popup .content2 {
  padding: 1.2rem 0;
}

.service-popup .content2 .van-cell {
  padding: 0 1.5rem;
  margin: 2rem 0;
}

.service-popup .content2 .van-cell::after {
  border-width: 0;
}

.service-popup .content2 .van-cell .van-icon {
  font-size: 4rem;
  margin-right: 0.6rem;
}

.service-popup .content2 .van-cell .van-cell__title {
  font-size: 2rem;
}

.service-popup .content2 .van-cell .van-cell__label {
  font-size: 1.6rem;
}


.teacher-popup {
  height: 50% !important;
  ;
}

.teacher-popup .content2 {
  padding: 1.2rem 0;
}

.teacher-popup .content2 .van-cell {
  padding: 0 1.5rem;
  margin: 2rem 0;
}

.teacher-popup .content2 .van-cell::after {
  border-width: 0;
}

.teacher-popup .content2 .van-cell .van-icon {
  font-size: 4rem;
  margin-right: 0.6rem;
  color: #666666;
}

.teacher-popup .content2 .van-cell .van-cell__title {
  font-size: 2rem;
}

.teacher-popup .content2 .van-cell .van-cell__label {
  font-size: 2rem;
  line-height: 4rem;
}

.teacher-popup .content2 .pho-wx {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.shop_detail {
  background-color: #e7e9ed;
}

.shop_detail .van-tabs__content {
  padding-bottom: 6rem;
}

.shop_detail .courseList {
  margin: 1rem;
  border-radius: 2rem;
  background-color: #ffffff;
}

.shop_detail .courseList .courseListTitle {
  padding: 1.5rem 1rem;
  background-color: #ffffff;
  font-size: 2rem;
  line-height: 2rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.shop_detail .courseList .courseListTitle {
  padding: 1.5rem 1rem;
  background-color: #ffffff;
  font-size: 2rem;
  line-height: 2rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.shop_detail .courseList .van-cell {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding: 1rem;
}

.shop_detail .courseList .courseListTitle .van-tag {
  width: auto;
  padding: 0.4rem 1rem;
  line-height: 2rem;
  font-size: 1.8rem;
  justify-content: center;
}

.van-icon-like:before {
  color: #ee0a24 !important;
}

.shop_detail .evaluation {
  margin: 1rem;
  border-radius: 2rem;
  background-color: #ffffff;
}

.shop_detail .evaluation .van-cell {
  border-radius: 1rem;
  padding: 1.5rem 1rem;
}

.shop_detail .evaluation .van-cell .van-icon {
  float: left;
  top: 0;
  margin: 0.3rem 0.6rem;
}

.shop_detail .evaluation .van-cell .van-icon .van-icon__image {
  border-radius: 100%;
}

.shop_detail .evaluation .van-cell .van-cell__title {
  color: #333;
  font-size: 1.8rem;
}

.shop_detail .evaluation .van-cell .evaluationContent {
  color: #333;
  font-size: 1.8rem;
  line-height: 3.2rem;
}

.shop_detail .evaluation .title-1 {
  height: 4rem !important;
}

.shop_detail .evaluation .title-1 .van-cell__title {
  color: #333;
  font-size: 2.2rem;
  font-weight: 600;
  padding: 0 0.5rem;
}

.shop_detail .title-2 {
  height: 4rem !important;
}

.shop_detail .title-2 .van-cell__title {
  color: #333;
  font-size: 2.2rem;
  font-weight: 600;
  padding: 0 1rem !important;
}


.course-detail-title .van-nav-bar__content .van-icon {
  color: #ffffff;
}

.course-detail-title .van-nav-bar__content .van-nav-bar__left .van-icon {
  font-size: 3rem;
  padding: 0.5rem;
  border-radius: 5rem;
  background-color: #666666;
  opacity: 0.6;
  margin-left: 1rem;
  color: #ffffff;
}

.course-detail-title .van-nav-bar__content .van-nav-bar__right .van-icon {
  padding: 0.5rem;
  font-size: 3rem;
  border-radius: 5rem;
  background-color: #666666;
  opacity: 0.6;
  margin-right: 1rem;
  color: #ffffff;
}
.detail img{
  width: 100%;
}
</style>
