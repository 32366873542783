<template>
  <div class="website">
    <van-nav-bar class="cart-title" fixed left-arrow @click-left="onBack" @click-right="isDelete = !isDelete">
      <template #title>
        查看内容
      </template>
    </van-nav-bar>
    <div v-html="content" style="padding-top:6rem"></div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import axios from "axios"


import { websiteInfo } from '@/request/api'
import {Toast} from "vant";

export default {
  // beforeRouteEnter(to, from, next) {
  //   let that = this;
  //   if (!to.params || !to.params.id) {
  //       next({
  //           path: from.name,
  //           query: {} // 将跳转的路由path作为参数，登录成功后跳转到该路由
  //       })
  //   }
  //   next();
  // },
  metaInfo() {
    return {
      title: this.title, //设置页面title
    }
  },
  data() {
    return {
      title: this.getBranchCompany(),
      content: '',
      branch: this.getBranch(),
      type: 1
    };
  },
  methods: {
    onBack() {
      history.back();
      window.android.back()
    },
    userKefu() {
      this.$router.push({ path: '/user' });
    },
    website() {
      let that = this;
      let branchId = that.branch;
      let type  = that.type;
      websiteInfo({branchId,type})
          .then(res => {
            if (res.data.code == 1) {
              that.content = res.data.data.content;
            }
          })
          .catch(err => {
            console.log("error");
            console.log(err);
          })
    },
    handleClickCourseDetail(id) {
      this.$router.push({
        name: 'CourseDetail',
        params: {
          id
        }
      })
    },
    copy(title) {
      this.$copyText(title).then(
          function(e) {
            Toast('复制成功')
          },
          function(e) {
            Toast('复制错误')
          }
      );
    }
  },
  created() {
    if(this.$route.query.type) {
      this.type = this.$route.query.type;
    }
    if(this.$route.query.branch) {
      this.branch = this.$route.query.branch;
    }
    this.title = this.getBranchCompany()
    this.website();
  },
  components: {

  },

}
</script>
<style lang="less">

.website {
  padding: 2rem 1rem;
}
.website strong {
  font-weight: 600;
}
</style>
