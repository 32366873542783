<template>
  <div class="course">
    <van-nav-bar title="课程在线试听" fixed left-arrow @click-left="onBack"></van-nav-bar>
    <!--        <van-nav-bar title="课程在线试听" class="top" @click-left="onBack"/>-->
    <!-- <van-search v-model="SearchValue"  shape="round" placeholder="请输入搜索关键词1" disabled
      @click="handleClickSearch"
     /> -->
    <transition name="van-slide-right" mode="out-in">
      <router-view></router-view>
    </transition>

    <div class="banner">
      <van-swipe class="my-swipe" :autoplay="3000" height="auto" indicator-color="#1989fa">
        <van-swipe-item v-for="item in banner" :key="item.id">
          <img :src="item.url" width="100%" style="display: block;" alt/>
        </van-swipe-item>
      </van-swipe>
    </div>
    <van-tabs v-model:active="active" color="#1989fa" swipeable sticky animated class="shop_page">
      <van-tab :title="item.cate_name" :name="item.id?'s'+item.id:''" v-for="(item,index) in courseList" :key="index">
        <div class="courseList" v-for="(item2,index2) in item.audition" :key="index2">
          <van-card
              class="course-card"
              :desc="item2.course_content"
              :title="item2.title"
              :thumb="item2.photopath"
              @click="handleClickCourseDetail(item.id,item2.stage,item2.id)"
          >
            <template #tags>
              <div class="tags">
                <van-tag plain round type="warning" v-for="(item3,index3) in item2.labelarr"
                         :key="index3">{{item3}}
                </van-tag>
              </div>
            </template>
            <template #bottom>
              <!--            <span class="price">￥{{item.course_price}}</span>-->
              <van-button round type="danger" size="mini" class="audition">试听学习</van-button>
            </template>
          </van-card>
        </div>
        <!--        {{ item }}-->
      </van-tab>
      <van-tab title="" v-if="courseList.length>1"></van-tab>
      <!--      <van-tab title="中级综合">-->
      <!--        内容 2-->
      <!--      </van-tab>-->
      <!--      <van-tab title="中级实务">-->
      <!--        内容 2-->
      <!--      </van-tab>-->
      <!--      <van-tab title="中级法规">-->
      <!--        内容 2-->
      <!--      </van-tab>-->
      <!--      <van-tab title="">-->
      <!--        内容 2-->
      <!--      </van-tab>-->
    </van-tabs>


  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import axios from "axios"


import {GetHomeList, onCourseDetail, onCourseList, onShareUrl} from '@/request/api'
import Qs from 'qs'
import wx from 'weixin-js-sdk';

export default {
  name: 'Course',
  // metaInfo: {
  //     title: this.website.seo_title, //设置页面title
  //     meta: [{                 //设置页面meta
  //         name: 'keyWords',
  //         content: this.website.seo_keywords
  //     },
  //         {
  //             name: "description",
  //             content: this.website.xue_description,
  //         },
  //     ]
  // },
  metaInfo () {
    return {
      title: (this.website.seo_title ? this.website.seo_title : this.title), //设置页面title
      meta: [{                 //设置页面meta
        name: 'keyWords',
        content: this.website.seo_keywords
      },
        {
          name: "description",
          content: this.website.seo_description,
        },
      ]
    }
  },
  data() {
    return {
      SearchValue: "",
      active: 0,
      item: '',
      //轮播图数组
      banner: [],
      show: false,
      website: [],
      courseList: [],
      jssdk: [],
      title: '课程免费试听',
      photopath: 'https://cdnoss.ksyt.com.cn/school/51e7b00a0c42d29e1c7ce182ca20634.jpg',
      groupId: '',
      subjectId: ''
    };
  },
  methods: {
    handleClickCourseDetail(subjectId, stageId, courseId) {
      // this.$router.push('/auditionDetail?subjectId=' + subjectId + '&stageId=' + stageId + '&courseId=' + courseId)
      this.$router.push({
        name:'AuditionDetail',
        query:{
          subjectId,
          stageId,
          courseId
        }
      })
      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("changeIsShowPopupShow", true)
    },
    onBack() {
      this.$router.push('index')
    },
    //免费试听列表
    getFreeAudition(subjectId,groupId) {
      let that = this;
      if(!subjectId) {
        subjectId = that.subjectId
        if(!subjectId) {
          subjectId = that.getSubjectId();
        }
      }
      if(!groupId) {
        groupId = that.groupId;
        if(!groupId) {
          groupId = this.getGroupId()
        }
      }
      onCourseList({groupId,subjectId})
          .then(res => {
            if (res.data.code == 1) {
              that.courseList = res.data.data.data;
              that.banner = res.data.data.banner;
              that.website = res.data.data.website;
              that.active = 's'+subjectId
              that.onWxConfig();
            }
            console.log(that.active);
          })
          .catch(err => {
            console.log("error");
            console.log(err);
          })
    },
    onWxConfig() {
      let that = this;
      let link = location.href.split('#')[0]+'#/freeAudition?groupId='+that.groupId+'&subjectId='+that.subjectId;
      onShareUrl({url: location.href.split('#')[0]})
          .then(res => {
            if (res.data.code == 1) {
              wx.config({
                debug: false,// 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: res.data.data.sdk.appId,         // 必填，公众号的唯一标识，填自己的！
                timestamp: res.data.data.sdk.timestamp, // 必填，生成签名的时间戳，刚才接口拿到的数据
                nonceStr: res.data.data.sdk.nonceStr,   // 必填，生成签名的随机串
                signature: res.data.data.sdk.signature, // 必填，签名，见附录1
                jsApiList: [
                  'onMenuShareTimeline',
                  'onMenuShareAppMessage'
                ]
              })

              console.log(link);
              wx.ready(function () {
                //分享到朋友圈
                wx.onMenuShareTimeline({
                  title:  that.title,   // 分享时的标题
                  link: link,     // 分享时的链接
                  desc: '高清视频，同步字幕，名师精讲，像看电影一样的学习体验，助你轻松备考！',
                  imgUrl: that.photopath,    // 分享时的图标
                  success: function () {
                    console.log("分享成功");
                  },
                  cancel: function () {
                    console.log("取消分享");
                  }
                });
                //分享给朋友
                wx.onMenuShareAppMessage({
                  title:  that.title,
                  desc: '高清视频，同步字幕，名师精讲，像看电影一样的学习体验，助你轻松备考！',
                  link: link,
                  imgUrl: that.photopath,
                  type: '',
                  dataUrl: '',
                  success: function () {
                    console.log("分享成功");
                  },
                  cancel: function () {
                    console.log("取消分享");
                  }
                });
              });
              wx.error(function(res){
                console.log(res);
                // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
              })
            }
          })
          .catch(err => {
            console.log("error");
            console.log(err);
          })
    }
  },
  created() {
    if(this.$route.query.groupId) {
      this.groupId = this.$route.query.groupId;
    } else if(this.getGroupId()) {
      this.groupId = this.getGroupId();
    }
    if(this.$route.query.subjectId) {
      this.subjectId = this.$route.query.subjectId
    }
  },
  mounted() {
    this.getFreeAudition();
  },
  components: {},

}
</script>
<style lang="less">

@import '~@/assets/styles/common.less';

.course {
  background-color: #ffffff;
  // overflow: hidden;
  padding: 0 0.8rem 8rem 0.8rem;
}

.popup-shadow {
  width: 100%;
  height: 100%;
  position: absolute;
  // z-index: 99999;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, .5);
  z-index: 20;
}

// .slide-enter, .slide-leave-to {
//   // 过渡之前的样式写在这里
//    right: -100%;
// }
// .slide-enter-active,.slide-leave-active  {
//   // 过渡属性写在这里
//   transition: all .3s;
// }
// .slide-enter-to, .slide-leave {
//   // 过渡之后的样式写在这里
//    right: 0;
// }
.shop_page .van-sticky--fixed {
  position: fixed;
  top: 5.9rem;
  padding: 0 0.8rem 0.8rem 0.8rem;
  z-index: 9998;
}

.van-tab {
  padding: 0 1rem !important;
}
</style>
